import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Dropdown component is a lightweight context menu for housing navigation and actions.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`Dropdown.Button`}</inlineCode>{` as the trigger for the dropdown, or use a custom `}<inlineCode parentName="p">{`summary`}</inlineCode>{` element if you would like. `}<strong parentName="p">{`You must use a `}<inlineCode parentName="strong">{`summary`}</inlineCode>{` tag in order for the dropdown to behave properly!`}</strong>{`. You should also add `}<inlineCode parentName="p">{`aria-haspopup="true"`}</inlineCode>{` to custom dropdown triggers for accessibility purposes. You can use the `}<inlineCode parentName="p">{`Dropdown.Caret`}</inlineCode>{` component to add a caret to a custom dropdown trigger.`}</p>
    <p>{`Dropdown.Menu wraps your menu content. Be sure to pass a `}<inlineCode parentName="p">{`direction`}</inlineCode>{` prop to this component to position the menu in relation to the Dropdown.Button.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Dropdown direction='w'>
  <Dropdown.Button>Dropdown</Dropdown.Button>
  <Dropdown.Menu direction='sw'>
    <Dropdown.Item>Item 1</Dropdown.Item>
    <Dropdown.Item>Item 2</Dropdown.Item>
    <Dropdown.Item>Item 3</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
`}</code></pre>
    <h2>{`With custom button`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Dropdown>
  <summary>
    Dropdown
    <Dropdown.Caret/>
  </summary>
  <Dropdown.Menu direction='sw'>
    <Dropdown.Item>Item 1</Dropdown.Item>
    <Dropdown.Item>Item 2</Dropdown.Item>
    <Dropdown.Item>Item 3</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
`}</code></pre>
    <h2>{`Manage the open state manually`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` element is built to also let you manage the open state and toggle functionality if necessary. Just provide values to the `}<inlineCode parentName="p">{`open`}</inlineCode>{` and `}<inlineCode parentName="p">{`onToggle`}</inlineCode>{` props.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Closing the dropdown on outside clicks will not function automatically if you chose to provide your own `}<inlineCode parentName="p">{`open`}</inlineCode>{` state. You'll need to implement this yourself. You can use the `}<inlineCode parentName="p">{`onClickOutside`}</inlineCode>{` prop to implement and customize this behavior.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={false}>
  {([open, setOpen]) => {
    
    const handleToggle = (e) => setOpen(e.target.open)
    const handleClickOutside = () => setOpen(false)

    return (
      <Dropdown open={open} onToggle={handleToggle} onClickOutside={handleClickOutside}>
        <Dropdown.Button>Dropdown</Dropdown.Button>
        <Dropdown.Menu direction='sw'>
          <Dropdown.Item>Item 1</Dropdown.Item>
          <Dropdown.Item>Item 2</Dropdown.Item>
          <Dropdown.Item>Item 3</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }}
</State>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Dropdown, Dropdown.Menu, Dropdown.Button, Dropdown.Caret, and Dropdown.Item all get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`The Dropdown component is extended from the `}<a parentName="p" {...{
        "href": "/atoms/Details"
      }}><inlineCode parentName="a">{`Details`}</inlineCode></a>{` component and gets all props that the `}<a parentName="p" {...{
        "href": "/atoms/Details"
      }}><inlineCode parentName="a">{`Details`}</inlineCode></a>{` component gets. They are listed below, but you may reference the `}<a parentName="p" {...{
        "href": "/atoms/Details"
      }}><inlineCode parentName="a">{`Details`}</inlineCode></a>{` docs for more details on how to manage your own `}<inlineCode parentName="p">{`open`}</inlineCode>{` state.`}</p>
    <h4>{`Dropdown`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`defaultOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the initial open/closed state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`overlay`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets whether or not element will close when user clicks outside of it`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Use the open prop if you'd like to manage the open state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onToggle`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Called whenever user clicks on `}<inlineCode parentName="td">{`summary`}</inlineCode>{` element. If you are controlling your own `}<inlineCode parentName="td">{`open`}</inlineCode>{` state this will be the only function called on click, otherwise it's called before the internal `}<inlineCode parentName="td">{`handleToggle`}</inlineCode>{` function.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClickOutside`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to call whenever user clicks outside of the Details component. This is optional and only necessary if you are controlling your own `}<inlineCode parentName="td">{`open`}</inlineCode>{` state.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Dropdown.Menu`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`direction`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'sw'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the direction of the dropdown menu. Pick from 'ne', 'e', 'se', 's', 'sw', or 'w'`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Dropdown.Button`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`none`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Use the `}<inlineCode parentName="td">{`onClick`}</inlineCode>{` handler to add additional functionality when the button is clicked, such as fetching data.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Dropdown.Caret`}</h4>
    <p>{`No additional props.`}</p>
    <h4>{`Dropdown.Item`}</h4>
    <p>{`No additional props.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      